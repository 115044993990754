<template lang="pug">
v-col.pa-1.pa-lg-2(cols="6", lg="3")
  v-skeleton-loader(v-bind="attrs", type="card", v-if="loaderProduct")
  v-card.my-2.elevation-3(v-else)
    v-dialog(v-model="videoDialog", width="800", v-if="product.video")
      template(v-slot:activator="{ on, attrs }")
        v-btn(
          fab,
          dark,
          color="red",
          style="position: absolute; z-index: 2; right: 10px; top: 10px",
          v-bind="attrs",
          v-on="on"
        )
          v-icon mdi-play
      v-card
      .video-product-modal
        iframe.video-product__iframe(
          v-if="product.video",
          :src="product.video",
          title="YouTube video player",
          frameborder="0",
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen
        )

    v-container.pa-0(@click="goProduct(product.id)", style="cursor: pointer")
      v-container.pa-0.pa-lg-4
        v-container.pa-0.d-flex.flex-row.align-center.justify-center(
          :class="[{ 'image-mobile': isMobile }, 'image-pc']"
        )
          v-img.image-item-product(:src="product.image")
          iframe.video-product__iframe(
          v-if="product.video  && product.name == 'ZAP1'",
          :src="product.video",
          title="YouTube video player",
          frameborder="0",
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen
        )
      .d-flex.flex-row.align-center.justify-center(style="height: 70px" v-if="product.name != 'ZAP1'")
        .d-flex.text-overline.text-lg-button.px-2(style="line-height: 1.3") 
          p(style="font-size: 18px; font-weight: bold") {{ product.name }}

      .d-flex.flex-row.align-center.justify-center(style="height: 70px" v-if="product.name != 'ZAP1'")
        .d-flex.text-overline.text-lg-button.px-2(style="line-height: 1.3")
          p(style="font-size: 12px") {{ product.resumen }}

      //-v-card-subtitle.desktop.text-subtitle-1.text-lg-h6(v-if="!showForProducts") Precio Senior: $ {{ product.precio_venta }}
      //-v-card-subtitle.mobile.text-subtitle-1.text-lg-h6(v-if="!showForProducts") Precio Senior: $ {{ product.precio_venta }}
      //-v-card-subtitle(style="margin-top: -35px;").desktop.text-subtitle-1.text-lg-h6(v-if="!showForProducts") Precio Junior: $ {{ product.precio_junior }}
      //-v-card-subtitle(style="margin-top: -35px;").mobile.text-subtitle-1.text-lg-h6(v-if="!showForProducts") Precio Junior: $ {{ product.precio_junior }}
      v-simple-table(
        style="border-top: solid 1px #d3d3d3",
        v-if="transito != 1 && showForProducts && product.name != 'ZAP1'"
      ) 
        thead
          tr
            th(width="60vw") Nivel de Inversion
            th(width="40vw") Monto
        tbody
          tr
            td Senior
            td $ {{ product.precio_venta }}
          tr
            td Junior
            td $ {{ product.precio_junior }}
          tr(v-if="product.cantidad_minima")
            td
              b Cantidad Mínima
            td
              b {{ product.cantidad_minima }}

    v-dialog(
      v-model="dialogPalette",
      :width="dialogWidth",
      v-if="showForPallets"
    )
      template(v-slot:activator="{ on, attrs }")
        v-btn.pa-4.mt-4(
          dark,
          small,
          color="terciary",
          style="width: 100%",
          v-bind="attrs",
          v-on="on"
        )
          | Ver precios
          v-icon.pl-2 mdi-eye-settings
      v-card
        .d-flex.align-center.justify-center.text-lg-h6.py-4.px-.text-center {{ product.name }}
        v-container
          table.mx-auto(
            style="border-collapse: collapse; border: grey 1px solid"
          )
            thead.primary.white--text(style="font-size: 14px")
              tr
                th.pa-1.pa-lg-4(
                  style="border: grey 1px solid; border-right: grey 1px solid"
                ) Cantidad Importadores
                th.pa-1.pa-lg-4(style="border: grey 1px solid") Precio por Importador
                th.pa-1.pa-lg-4(
                  style="border: grey 1px solid; border-left: grey 1px solid"
                ) Unidades por importador
            tbody
              tr(v-for="(item, index) in datatable", :key="index")
                td.text-center(style="border: grey 1px solid") {{ item.cantidad_importadores | aproximar }}
                td.text-center(style="border: grey 1px solid") $ {{ item.precio_por_importador | aproximar }}
                td.text-center(style="border: grey 1px solid") {{ item.unidades_por_importador | aproximar }}
          .d.text-button.text-center.py-4 Precio por pieza : $ {{ (this.product.precio_venta / this.product.unidades) | aproximar }}

    v-dialog(v-model="dialog", :width="dialogWidth", v-if="showForProducts")
      template(v-slot:activator="{ on, attrs }")
        v-btn.pa-4(
          dark,
          small,
          color="primary",
          style="width: 100%",
          v-bind="attrs",
          v-on="on"
        )
          v-icon mdi-cart
          | Agregar
      v-card
        .d-flex.align-center.justify-center.text-button.text-lg-h6.py-4.px-2 Producto : {{ product.name }}
        v-container
          v-row(no-gutters)
            v-col.pa-0(cols="4")
              v-img(:src="product.image")
            v-col.pa-8(cols="8")
              v-text-field(
                label="Cantidad:",
                required,
                type="number",
                min="1",
                max="999",
                v-model="quantity"
              )
              p.red--text(
                style="font-size: 12px",
                v-if="addpUshed && !quantity"
              ) Nesesita agregar una cantidad
              v-btn(
                dark,
                color="primary",
                style="width: 100%",
                @click="addCartProduct()"
              )
                v-icon mdi-cart
                | Agregar
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ItemProduct",
  props: ["product", "autenticatedByKey", "transito"],
  data: () => ({
    addpUshed: false,
    videoDialog: false,
    dialog: false,
    dialogPalette: false,
    quantity: null,
    attrs: {
      class: "mb-10",
      boilerplate: false,
      elevation: 2,
    },
  }),
  filters: {
    aproximar: (number) => {
      return Number.parseFloat(number).toFixed(2);
    },
  },
  watch: {
    videoDialog(value) {
      if (!value) {
        const $modalVideo = document.querySelector(".video-product__iframe");
        $modalVideo.src = this.product.video;
      }
    },
  },
  computed: {
    ...mapGetters({
      loaderProduct: "getLoaderItemProduct",
      isMobile: "getIsMobile",
      country: "getCountryUser",
      isAuthenticated: "isAuthenticated",
    }),
    dialogWidth() {
      if (this.isMobile) {
        return "100%";
      } else {
        return "30%";
      }
    },
    datatable() {
      if (this.product.cantidad_importadores) {
        const data = [];
        for (
          let index = 0;
          index < this.product.cantidad_importadores;
          index++
        ) {
          var row = {};
          row.cantidad_importadores = index + 1;
          row.precio_por_importador = this.product.precio_venta / (index + 1);
          row.unidades_por_importador = this.product.unidades / (index + 1);
          data.push(row);
        }
        return data;
      } else {
        return [];
      }
    },
    showForProducts() {
      return (
        !this.product.cantidad_importadores &&
        (this.country !== "PE" ||
          this.isAuthenticated ||
          this.autenticatedByKey)
      );
    },
    showForPallets() {
      return (
        this.product.cantidad_importadores &&
        (this.country !== "PE" ||
          this.isAuthenticated ||
          this.autenticatedByKey)
      );
    },
  },
  methods: {
    addCartProduct() {
      this.addpUshed = true;
      if (this.quantity) {
        this.dialog = false;
        this.$store.commit("ADD_PRODUCT_CART", {
          product: this.product,
          quantity: this.quantity,
        });
        this.$store.commit("UPDATE_TOTAL_CART");
        if (this.isMobile) {
          this.$router.push("/cart-mobile");
        } else {
          this.$store.commit("SHOW_SHOPPING_CART", true);
        }
      }
    },
    goProduct(productId) {
      this.$store.commit("SET_PRODUCT_DETAIL", this.product);
      if (this.$route.params.transito) {
        this.$router.push(`/product/transito/${productId}`);
      } else {
        this.$router.push(`/product/${productId}`);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.mobile {
  display: none;

  @media (max-width: 992px) {
    display: block;
  }
}

.desktop {
  display: block;

  @media (max-width: 992px) {
    display: none;
  }
}
.image-item-product {
  max-width: 100%;
  max-height: 100%;
}
.image-pc {
  height: 300px;
}
.image-mobile {
  height: 200px;
}
.video-product-modal {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56%;
}
.video-product__iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
</style>
