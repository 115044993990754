<template>
  <v-row justify="center">
    <!-- <v-dialog v-model="dialogsPrecio" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h5 text-center"> </v-card-title>
        <v-card-text class="mt-10 text-center">
          <img
            src="@/assets/atentionicon.svg"
            width="200px"
            class="mx-auto"
            style="margin-bottom: 20px;"
            alt=""
          />
          <p
            style="font-weight: bold; font-size: 28px; line-height: 38px; text-align: center; text-transform: none !important"
          >
            <span
              style="color: #348EEF; font-size: 40px; font-weight: 800; margin-top: 10px"
              >IMPORTANTE</span
            >
            <br />
            <span
              >Para obtener
              <span style="background: #ffe000; padding: 0px 10px; color: black"
                >PRECIO</span
              >
              inscribete en nuestro grupo cerrado</span
            >
            <br /><br />

            <v-btn
              class="mt-1"
              x-large
              color="#348EEF"
              dark
              @click.native="cerrarPro()"
              >ENTENDIDO</v-btn
            >
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="dialogsPrecio" persistent  max-width="450" >
      <v-card style="background: none; text-align: center" class="desktop">
        <img src="@/assets/ventanaweb.svg" alt="">
        <div style="margin-top: -110px;">
          <p style="margin: auto; color: white; font-size: 20px; font-weight: bold">Los precios son puestos en Perú <img style="margin: auto;" width="30" src="@/assets/peruRound.svg" alt=""></p>
        </div>
        <v-btn @click.native="cerrarPro()" elevation="0" large style="margin-top: 10px; font-size: 1.2vw; padding: 10px; border-radius: 10px; color: #348EF0 ">ENTENDIDO</v-btn>
      </v-card>

      <v-card style="background: none; text-align: center" class="mobile">
        <img src="@/assets/ventanamovil.svg" alt="">
        <div style="margin-top: -180px;">
          <p style="margin: auto; color: white; font-size: 22px; font-weight: bold">Los precios son puestos en Perú <img style="margin: auto;" width="30" src="@/assets/peruRound.svg" alt=""></p>
        </div>
        <v-btn @click.native="cerrarPro()" elevation="0" x-large style="margin-top: 10px; font-size: 5.5vw; padding: 30px; border-radius: 10px; color: #348EF0 ">ENTENDIDO</v-btn>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "dialogs",
  props: {
    nameC: ""
  },
  data: () => {
    return {
      dialogsPrecio: true
    };
  },
  computed: {
    ...mapGetters("getCampaignSelected")
  },

  methods: {
    ...mapActions(["setCampaignSelected"]),
    cerrarPro() {
      localStorage.setItem(this.nameC, this.nameC);
      this.dialogsPrecio = !this.dialogsPrecio;
      this.$store.state.val1 = true;
    }
  }
};
</script>

<style lang="scss">
.v-dialog--active{
  height: 600px !important;
}
</style>