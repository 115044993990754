<template lang="pug">
v-container.background.pa-0(fluid)
  a.desktop(
    v-if="$route.params.transito",
    style="position: fixed; height: auto; background: #3f51b5; width: 100%; font-size: 38px; margin: auto; text-align: center; padding: 15px; margin-top: 32px; color: white; font-weight: 700; z-index: 9",
    @click="lista = true"
  ) COMPRALE A NUESTROS IMPORTADORES --> CLICK AQUI PARA EL LISTADO <--
  a.mobile(
    v-if="$route.params.transito",
    style="position: fixed; height: auto; background: #3f51b5; width: 100%; font-size: 3.8vw; margin: auto; text-align: center; padding: 15px; margin-top: 95px; color: white; font-weight: 700; z-index: 9",
    @click="lista = true"
  ) COMPRALE A NUESTROS IMPORTADORES <br> --> CLICK AQUI PARA EL LISTADO <--
  .d-flex.flex-column.d-lg-none.secondary.accent2--text(
    style="width: 100%; position: fixed; z-index: 3"
  )
    //- v-row(no-gutters).py-1.primary.justify-center.text-button.text-center(style="line-height:1.5") {{ getCampaignSelected.description }}
    a.py-1.justify-center.text-center.cyan.lighten-2.black--text(no-gutters)(
      style="line-height: 1.5; cursor: pointer; text-decoration: none",
      href="https://www.pic-cargo.com/curso-como-importar-china/"
    )
      .d.text-body-1.d-flex.flex-row.justify-center(style="font-weight: bold")
        .d APRENDE A IMPORTAR
        .d.px-1.accent--text GRATIS
        .d.red--text ⏩ (Click Aqui)

    //-.py-1.accent2(v-if="!isAuthenticated")
    //-  .d-flex.flex-column
    //-    .d-flex.flex-row.justify-center
    //-      .d.red--text(style="font-weight:bold").mx-1 IMPORTANTE:
    //-      .d.black--text PARA LOS PRECIOS, 
    //-    .d-flex.flex-row.justify-center.black--text
    //-      .d(style="") INSCRIBETE EN NUESTRO GRUPO <b><a href="/faq/activo" target="_blank">CLICK AQUI </a> </b>
    v-row.pa-0(no-gutters, style="position: absolute; top: 56px; width: 100%")
      v-col.text-center.pa-0.py-2.secondary(
        @click="goHome()",
        style="cursor: pointer; z-index: 10; font-weight: bolder; font-size: 14px"
      )
        | VER MÁS CAMPAÑAS
      v-divider(vertical, style="background-color: white")
      v-col.text-center.pa-0.accent.py-2(
        @click="goFaq()",
        style="cursor: pointer; z-index: 10; font-weight: bolder; font-size: 14px"
      )
        v-icon.accent2--text.mr-2 mdi-account-group
        | COMO PARTICIPAR
    //-v-row(no-gutters).pa-0
    //-  v-col.text-center.pa-0.accent.py-2(@click="goFaq()", style="cursor:pointer; z-index:10; font-weight:bolder; font-size:16px;")
    //-    v-icon.accent2--text.mr-2 mdi-account-group
    //-    | COMO PARTICIPAR
    //- div(style="background: white; text-align: center; margin-top: 95px; height: 70px; position: absolute; width: 100%; left: 0px; " v-if="!isAuthenticated && !autenticatedByKey" )
      p(style="color: #1C2B53; font-size: 4.5vw; font-weight: 800; text-decoration: none !important; margin-top: 12px") LOS PRECIOS SON PUESTOS EN PERÚ
        img(width="25px" class="ml-2" src="@/assets/peruRound.svg")
      //-a(target="_blank"  :href="`https://wa.me/?text=Te he compartido esta información valiosa que vi en la página de Pic Cargo, se que te encantará el siguiente link ${HostLocation}`")

  v-navigation-drawer(v-model="menuCatalogs", fixed, temporary, right)
    catalog-selector
  .container-inner.d-none.d-lg-flex.mx-auto.pt-lg-6(style="width: 100%")
    //-.terciary.d-flex.flex-column.flex-lg-row.text-body-2.text-lg-body-1.py-4.px-4(style="color:white; position:fixed; z-index:3; width:100%;")
      .d-flex.flex-row.align-center
        span(@click="goCampaigns" style="cursor:pointer;").accent2--text CAMPAÑA
        v-icon.accent--text mdi-chevron-right
        span(v-if="loaderText") {{ getCampaignSelected.description }}
      v-spacer
      .d-flex.flex-row.align-center
        v-btn(dark, color="accent" @click="goFaq()")
          v-icon.accent2--text mdi-chevron-right
          | COMO PARTICIPAR EN NUESTRAS IMPORTACIONES
    //-.terciary(v-if="!isAuthenticated").d-flex.flex-column.flex-lg-row.text-body-2.text-lg-body-1.py-4.px-4.mt-10.justify-center.accent2.elevation-5(style="position:fixed; z-index:10; width:98.5%; max-width:1500px;")
      .d.red--text(style="font-weight:bold").mx-1 IMPORTANTE:
      .d IMPORTANTE PARA PRECIO INSCRIBETE EN NUESTRO GRUPO <b><a href="/faq/activo" target="_blank">CLICK AQUI </a> </b>
      .d(style="font-weight:bold") 

    v-card(
      style="position: fixed; top: 150px; left: 0px; z-index: 9; border-radius: 0px 35px 35px 0px; width: 12.5vw; background: #f57c00",
      v-if="!$route.params.transito",
      elevation="5"
    )
      v-card-text
        .d.red--text.mx-1(
          style="font-weight: bold; color: white !important; font-size: 24px"
        ) PRECIOS
        .d.ml-1(style="color: white !important; font-size: 18px") <b><a href="/faq/activo" target="_blank" style="text-decoration: none; color: white;">Click aquí </a> </b>
        .d(style="font-weight: bold") 
  .container-inner-products-catalog.mx-auto.accent2.elevation-1.mt-lg-14(
    style="width: 100%; max-width: 1500px"
  )
    v-row.py-3(no-gutters)
      //-v-col(cols="0" lg="2")
        .d-none.d-lg-flex.flex-row.justify-center
          catalog-selector(style="width:190px; position:fixed;")
      v-divider(vertical)
      v-col.px-0(cols="12", lg="12", style="margin-bottom: 100px")
        v-container.pa-0.pt-lg-0.d-flex.flex-column
          v-row.pt-2.pt-lg-0
            v-col(
              cols="12",
              md="6",
              v-if="this.$route.params.idCampaign == 70"
            ) 
              v-btn(
                block,
                style="color: white; background: #f68614; height: 70px; text-align: center; line-height: 10px !important; font-size: 16px",
                href="https://drive.google.com/file/d/1cdD3aaT7KcdGt8dnNLu1WMXz2M6hih2e/view",
                target="_blank"
              )
                h1(style="font-size: 19px; line-height: 10px") VER CATALOGO COMPLETO <br></br>
                  span(
                    style="font-size: 20px; font-weight: 800; text-shadow: #4b1d1d 2px 0px 2px"
                  ) (CLICK AQUI)
            v-col(
              cols="12",
              md="6",
              v-if="this.$route.params.idCampaign == 70"
            ) 
              v-btn(
                style="color: white; background: #259588; height: 70px; text-align: center; line-height: 10px !important",
                block,
                href="https://chat.whatsapp.com/LmVviqiKz9NHkVrEyU6Q8r",
                target="_blank"
              )
                v-icon.mr-2(size="42") mdi-whatsapp
                h1(style="font-size: 14px; line-height: 10px") UNETE A NUESTRO GRUPO DE <br></br> WHATSAPP PARA MAS INFORMACIÓN

            v-container.pa-0.mt-lg-n5
              v-row(no-gutters)
                item-product(
                  v-for="(product, index) in getProducts",
                  :key="index",
                  :transito="$route.params.transito ? 1 : 2",
                  :product="product",
                  :autenticatedByKey="autenticatedByKey"
                )
                //v-col(cols="6" md="3" style="margin-right: 30px") 
                  v-card 
                    <iframe width="300" height="350" src="https://www.youtube.com/embed/OrfKd52J_xI" title="ZAPATILLAS HIGH QUALITY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                //v-col(cols="6" md="3" style="margin-right: 30px") 
                  v-card 
                    <iframe width="300" height="350" src="https://www.youtube.com/embed/YK6OK1esWqI" title="Testeando Zapatillas (Muestras recien llegadas)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                //v-col(cols="6" md="3" style="margin-right: 30px") 
                  v-card 
                    <iframe width="300" height="350" src="https://www.youtube.com/embed/ugMulbBy988" title="Zapatillas High Quality Pic Runner" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                //v-col(cols="6" md="3" style="margin-right: 30px") 
                  v-card 
                    <iframe width="300" height="350" src="https://www.youtube.com/embed/j2E3XXRAJtE" title="Fabrica Testeando la Calidad de las Zapatillas Importacion Peru" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                //v-col(cols="6" md="3" style="margin-right: 30px") 
                  v-card 
                    <iframe width="300" height="350" src="https://www.youtube.com/embed/xPF6Gyh4ohU" title="Importacion de Zapatillas Panama Peru Venezuela" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                //v-col(cols="6" md="3" style="margin-right: 30px") 
                  v-card 
                    <iframe width="300" height="350" src="https://www.youtube.com/embed/972Hfwu9nKU" title="Importacion de Zapatillas Panama Peru Venezuela" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                //v-col(cols="6" md="3" style="margin-right: 30px") 
                  v-card 
                    <iframe width="300" height="350" src="https://www.youtube.com/embed/8TLo6I8pbKM" title="Importacion de Zapatillas Panama Peru Venezuela" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                //v-col(cols="6" md="3" style="margin-right: 30px") 
                  v-card 
                    <iframe width="300" height="350" src="https://www.youtube.com/embed/hICZq3drBGg" title="Importacion de Zapatillas Panama Peru Venezuela" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                //v-col(cols="6" md="3" style="margin-right: 30px") 
                  v-card 
                    <iframe width="300" height="350" src="https://www.youtube.com/embed/Nnlnrs0kOTs" title="Prueba de Calidad Importacion de Zapatillas Peru Panama Venezuela" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


  //- other-campaigns.d-none.d-lg-flex
  dialogs(
    v-if="!isAuthenticated && !autenticatedByKey && !$store.state.val1 && !$route.params.transito"
  )
  v-dialog(v-model="lista", width="700", scrollable)
    v-card(v-if="this.$route.params.idCampaign == 44")
      v-card-title LISTA DE IMPORTADORES
      v-card-text
        v-simple-table 
          thead 
            tr
              th Nombre Completo
              th Teléfono
              th Provincia
          tbody
            tr
              th Elio Reyna Silvestre
              th 982463161
              th Huancayo
            tr
              th Rodolfo Berrocal Oroz
              th 960377893
              th Lima
            tr
              th Julio Campos Valenzuela
              th 945149285
              th Lima
            tr
              th Juan Mauro Gonzales Peralta
              th 970137914
              th Lima
            tr
              th Felina Grosinda Tirado Leyva
              th 987088491
              th Lima
            tr
              th Victoria Yucyuc Torre
              th 982615804
              th Huaraz
            tr
              th Jesus Cornejo
              th 953758640
              th Lima
            tr
              th Josue Baca Atau
              th 947122943
              th Lima
            tr
              th Raúl Florez García
              th 921784311
              th Trujillo
            tr
              th Bryan Mogollon
              th 957656540
              th Ancash
            tr
              th Julio Cesar Llamoca Munoa
              th 950546785
              th Lima
            tr
              th Christopher Hurtado
              th 950083725
              th Lima
            tr
              th Noelia Carolina Zevallos Santa Maria
              th 959374031
              th Arequipa
            tr
              th Jose Antonio Maquito Lazaro
              th 920718879
              th Arequipa
            tr
              th Wilington Chanca Quesada
              th 955567519
              th Lima
            tr
              th Hawer Inocencio Toribio
              th 975558289
              th Lima
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import CatalogSelector from "../components/catalog/CatalogSelector.vue";
import ItemProduct from "../components/catalog/ItemProduct.vue";
import OtherCampaigns from "../components/OtherCampaigns.vue";
import dialogs from "./dialogs.vue";

export default {
  name: "Catalog",
  components: {
    CatalogSelector,
    ItemProduct,
    OtherCampaigns,
    //dialogs
  },
  data: () => ({
    menuCatalogs: false,
    loaderText: null,
    autenticatedByKey: false,
    dialogPrecios: true,
    dialogMsg: false,
    HostLocation: "",
    lista: false,
  }),
  beforeMount() {},
  methods: {
    ...mapActions([
      "getCampaignsAPI",
      "setCampaignSelected",
      "getRubrosAPI",
      "getCatalogsAPI",
      "setCatalogSelected",
      "getProductsCatalogAPI",
    ]),

    getStatus() {
      if (
        localStorage.getItem(this.getCampaignSelected.description) ===
        this.getCampaignSelected.description
      ) {
        this.dialogs = false;
      } else {
        this.dialogMsg = true;
      }

      localStorage.setItem("campana", this.getCampaignSelected.description);
    },

    async createValues() {
      this.loaderText = false;
      await this.getCampaignsAPI();
      await this.setCampaignSelected(this.$route.params.idCampaign);
      await this.getRubrosAPI(this.$route.params.idCampaign);
      await this.getCatalogsAPI(this.$route.params.idCampaign);
      await this.setCatalogSelected(this.$route.params.idCatalog);
      this.loaderText = true;
    },
    goHome() {
      this.$router.push("/");
      window.scroll(0, 0);
      window.scroll(0, 450);
    },
    showCatalogs() {
      this.$store.commit("STATUS_CATALOG_MOBILE", true);
    },
    goFaq() {
      this.$router.push("/faq/active2");
      window.scroll(0, 0);
    },
    goCampaigns() {
      this.$router.push("/");
      const altura = window.innerHeight;
      window.scroll(0, 0);
      window.scroll(0, altura - 100);
    },
  },
  computed: {
    ...mapGetters([
      "getCampaignSelected",
      "getCatalogSelected",
      "getProducts",
      "isAuthenticated",
    ]),
    numberItemsCatalog() {
      return this.catalog.length;
    },
    idCatalog() {
      return this.$route.params.idCatalog;
    },
  },
  watch: {
    idCatalog(idCatalogURL) {
      this.getProductsCatalogAPI(idCatalogURL);
    },
  },

  async mounted() {
    await this.getProductsCatalogAPI(this.$route.params.idCatalog);

    this.HostLocation = window.location.href;
    if (this.getCampaignSelected) {
      this.loaderText = true;
    } else {
      this.createValues();
      console.log("valores creados");
    }

    if (this.$route.params.idCampaign) {
      this.$store.state.campanaSelecta = true;
    } else {
      this.$store.state.campanaSelecta = false;
    }

    this.dialogMsg = true;

    //this.getStatus();
  },
  updated() {
    if (this.$route.params.key) {
      const key = this.$route.params.key;
      if (key === "pictemp123") {
        this.autenticatedByKey = true;
        console.log("autenticado por key");
      } else {
        this.autenticatedByKey = false;
      }
    } else {
      this.autenticatedByKey = false;
    }
  },
};
</script>
<style scoped lang="scss">
.container-inner-products-catalog {
  padding-top: 100px;
}

.v-btn__content {
  line-height: none !important;
}

.mobile {
  display: none;

  @media (max-width: 992px) {
    display: block;
  }
}

.desktop {
  display: block;

  @media (max-width: 992px) {
    display: none;
  }
}

@media (min-width: 1264px) {
  .container-inner-products-catalog {
    padding-top: 50px;
  }
}
</style>
