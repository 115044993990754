import { render, staticRenderFns } from "./Catalog.vue?vue&type=template&id=52172ff6&scoped=true&lang=pug&"
import script from "./Catalog.vue?vue&type=script&lang=js&"
export * from "./Catalog.vue?vue&type=script&lang=js&"
import style0 from "./Catalog.vue?vue&type=style&index=0&id=52172ff6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52172ff6",
  null
  
)

export default component.exports